@import url('https://fonts.googleapis.com/css2?family=Victor+Mono:wght@100;200;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  color: #fff;
  background-color: #07070a;
  font-family: 'Victor Mono', monospace;
  font-weight: 400;
}

.content {
  max-width: 1500px;
}

p {
  color: #484d4f;
}

.language button {
  position: relative;
}

.language button:before {
  content: '';
  width: 10px;
  height: 2px;
  background-color: #5bda99;
  position: absolute;
  bottom: -5px;
  right: 0;
  opacity: 0;
  transition: all 300ms ease;
}

.language button.active:before {
  opacity: 1;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.text-cta {
  color: #5bda99;
}

.bg-cta {
  background-color: #5bda99;
  color: #07070a;
}

.navigation li {
  right: 0;
  opacity: 1;
  transition: all 300ms ease;
}

.navigation .deactive {
  opacity: 0;
  right: -30px;
  z-index: -1;
}

.navigation a:hover i {
  right: 0;
}

a i {
  position: relative;
  right: 0;
  transition: all 150ms ease;
}

a:hover i {
  right: -5px;
}

.slick-track {
  display: flex;
  gap: 30px;
}

.cases-outer svg circle {
  transition: all 300ms ease;
}

.cases .slick-track {
  gap: 0;
}

.cases .slick-slide {
  display: flex;
}

.cases .slick-slide div > div {
  height: 100%;
}

.cases button.slick-arrow.slick-prev {
  position: absolute;
  bottom: 15px;
  right: 50px;
  z-index: 60;
}

.cases button.slick-arrow.slick-next {
  position: absolute;
  bottom: 15px;
  right: 20px;
  z-index: 60;
}

button.slider-nav.slick-arrow {
  transition: all 300ms ease;
}

button.slider-nav.slick-arrow.slick-disabled {
  opacity: 0.3;
}

@media ( max-width: 870px ) {

    h1 br,
    h2 br {
      display: none!important;
    }

    button.slick-arrow.slick-prev {
      position: absolute;
      bottom: 0;
    }

    button.slick-arrow.slick-next {
      position: absolute;
      bottom: 0;
    }
    .cases .slick-slide div > div {
      height: auto;
    }

}